* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: 'Fira Sans', sans-serif;

}

body  {
  background-color: #EEE;

}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center ;
  min-height: 100vh ;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #1114c0;
  font-size: 42px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
}
h1 strong {
  color: #1114c0;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  width: 66%;
  margin-bottom: 16px;
  transition: 0.4s;
}

.search-box input {
  display: block;
  appearance: none;
  outline: none;
  border: none;
border-radius: 16px;
  padding: 16px;
  width: 100%;
  transition: 0.4s;

}

.search-box:focus-within {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}
.results {
  max-width: 768px;
  margin: 0 auto;
}

.result {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #FFF;
  border-radius: 16px;
  transition: 0.4s;


}

.result:hover {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}
.result h3 {
  color: #888;
  font-size: 28px;
  margin-bottom: 16px;

}

.result p {
  color: #313131;
  font-size: 18px;
  margin-bottom: 16px;
}

.result a {

  display: inline-block;
  padding: 12px 16px;
  background-color: #A84FFF;
  color: #FFF;
  font-weight: 700;
  text-decoration: none;
  border-radius: 12px;
  transition: 0.4s;
 
}

.result a:hover {
  background-color: #d1d3d6;
}

.result p .searchmatch{
  font-weight: 700;
  color: #e32071;
}
.loading{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
}